import React from 'react';


const Contact = () => {
return(
<section className="contact" id="contact">
    <h2>Contact Us</h2>
    <p>If you have any questions about app, pricing, integration, or feedback, feel free to reach out to us at <a href="mailto:contact@affiliapi.com">contact@affiliapi.com</a>.</p>
</section>
);
}

export default Contact;