import React from 'react';
import './Integration.css';
import { FaSignInAlt, FaPaintBrush, FaLink } from 'react-icons/fa';

const Integration = () => {
    return (
        <section className="integration" id="integration">
            <h2>How It Works in 3 Easy Steps</h2>
            <div className="steps">
                <div className="step">
                    <FaSignInAlt className="icon" />
                    <div className="step-content">
                        <h3>Sign Up and Add Your Affiliate ID</h3>
                        <p>Create an account and enter your Amazon affiliate referral ID to get started.</p>
                    </div>
                </div>
                <div className="step">
                    <FaPaintBrush className="icon" />
                    <div className="step-content">
                        <h3>Customize Your Button Style and Copy Script Tag</h3>
                        <p>Select from a variety of styling options to match your site’s design, then copy the script tag to place on your site pages.</p>
                    </div>
                </div>
                <div className="step">
                    <FaLink className="icon" />
                    <div className="step-content">
                        <h3>Automated Link Conversion</h3>
                        <p>Every Amazon link you add to your site will automatically be converted into a sleek pricing button, helping you boost your conversions effortlessly.</p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Integration;
