import React from 'react';
import './App.css';
import Header from './components/Header';
import Footer from './components/Footer';
import Hero from './components/Hero';
import Testimonial from './components/Testimonial';
import SupportedAffiliate from './components/SupportedAffiliate';
import Infrastructure from './components/Infrastructure';
import Integration from './components/Integration';
import Pricing from './components/Pricing';
import FAQ from './components/FAQ';
import Contact from './components/Contact';

function App() {
    return (
        <div>
            <Header />
            <div id='main'>
            <Hero />
            <Integration />
            <Testimonial />
            <SupportedAffiliate />
            <Infrastructure />
            <Contact />
            </div>
            <Footer />
        </div>
    );
}

export default App;
