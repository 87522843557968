import React from 'react';
import './Infrastructure.css';

const Infrastructure = () => {
    return (
        <section className="infrastructure">
            <h2>Powerful Infrastructure Backed by AWS Cloud</h2>
            <div className="infrastructure-content">
                <div className="infrastructure-info">
                    <div>
                        <h3>Real-Time Monitoring</h3>
                        <p>We use AWS CloudWatch for 24/7 system monitoring, addressing any issues instantly.</p>
                    </div>
                    <div>
                        <h3>Scalable Infrastructure</h3>
                        <p>Powered by AWS's elastic services, we scale automatically to handle any traffic demands, ensuring optimal performance.</p>
                    </div>
                </div>
                <div className="infrastructure-stats">
                    <h3>99.9% Uptime</h3>
                    <p>Built on AWS's reliable infrastructure, AffiliAPI guarantees 99.9% uptime for uninterrupted service.</p>
                    <h3>Global Edge Locations</h3>
                    <p>Our APIs are served from multiple AWS edge locations, providing fast, low-latency responses no matter where your users are.</p>
                    <h3>Highly Secure</h3>
                    <p>Using AWS's secure API gateways, SSL encryption, and robust firewalls, your data is always protected.</p>
                    <h3>Data Backup & Redundancy</h3>
                    <p>Automated backups ensure your data is secure and always available.</p>
                </div>
            </div>
        </section>
    );
};

export default Infrastructure;
