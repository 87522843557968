import React from 'react';
import './SupportedAffiliate.css';
import { FaLink, FaPaintBrush, FaClock, FaHandshake, FaUsers } from 'react-icons/fa';

const SupportedAffiliate = () => {
    return (
        <section className="supported-affiliate">
            <h2>Why AffiliAPI is the Best Solution for Amazon Affiliates</h2>
            <p>AffiliAPI streamlines the entire affiliate process, making it easier than ever to integrate Amazon affiliate links and boost your conversions with minimal effort. Here’s why AffiliAPI is the top choice for Amazon affiliates:</p>
            <div className="features">
                <div className='left'></div>
                <div className='right'>
                    <div className="feature">
                        <FaLink className="icon" />
                        <div>
                            <h3>Automatic Link Conversion</h3>
                            <p>Transform every Amazon link on your website into a high-converting pricing button without any manual intervention. Say goodbye to the hassle of updating links yourself.</p>
                        </div>
                    </div>
                    <div className="feature">
                        <FaPaintBrush className="icon" />
                        <div>
                            <h3>Custom Styling</h3>
                            <p>Match your affiliate buttons with your website’s design using a variety of customizable styles. Create buttons that fit seamlessly into your brand’s look and feel.</p>
                        </div>
                    </div>
                    <div className="feature">
                        <FaClock className="icon" />
                        <div>
                            <h3>Real-Time Insights</h3>
                            <p>Stay on top of your affiliate performance with instant, real-time tracking. Access data that helps you make better decisions to maximize your earnings.</p>
                        </div>
                    </div>
                    <div className="feature">
                        <FaHandshake className="icon" />
                        <div>
                            <h3>Seamless Integration</h3>
                            <p>No complicated setup or coding required. Copy the script, paste it into your website, and you’re ready to go with fully automated affiliate link management.</p>
                        </div>
                    </div>
                    <div className="feature">
                        <FaUsers className="icon" />
                        <div>
                            <h3>Custom Support for Other Sellers</h3>
                            <p>Need more than just Amazon? No problem. AffiliAPI also offers custom integration support for other affiliate programs. Reach out to us to request custom solutions tailored to your needs.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default SupportedAffiliate;
