import React from 'react';
import './Footer.css';

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer-logo">
                <h1>AFFILIATE API</h1>
            </div>
            <div className="footer-links">
                <ul>
                    <li><a href="#root">Home</a></li>
                    <li><a href="#integration">Integration</a></li>
                    <li><a href="#infrastructure">Infrastructure</a></li>
                </ul>
                <ul>
                    <li><a href="#contact">Request a Meeting</a></li>
                    <li><a href="#contact">Contact</a></li>
                </ul>
            </div>
            <div className="footer-copyright">
                <p>© 2024 AffiliAPI</p>
            </div>
        </footer>
    );
};

export default Footer;
