import React from 'react';
import './Testimonial.css';

const Testimonial = () => {
    return (
        <section className="testimonial" id="testimonial">
            <h2>What Our Users Say</h2>
            <div className="testimonials">
                <div className="testimonial-box">
                    <p>"Setting up my Amazon affiliate links has never been easier. The styling options are a game-changer, and I love how seamless it is to integrate. Highly recommended for any affiliate marketer!"</p>
                    <p><strong>– John M., Affiliate Marketer</strong></p>
                </div>
                <div className="testimonial-box">
                    <p>"Affiliate API has completely transformed the way I manage affiliate links on my website. The automatic conversion to pricing buttons saves me so much time, and my earnings have significantly increased!"</p>
                    <p><strong>– Sarah J., Blogger</strong></p>
                </div>
                <div className="testimonial-box">
                    <p>"The simple setup and powerful automation have made a huge impact on my site’s performance. It’s like having an entire affiliate management team in a single tool!"</p>
                    <p><strong>– Emily R., Content Creator</strong></p>
                </div>
            </div>
        </section>
    );
};

export default Testimonial;
